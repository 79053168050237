@font-face {
	font-family: "primaryfont";
	src: local("primaryfont"),
	url("./font/Bubblegum.ttf") format("truetype");
}
@font-face {
	font-family: "secondaryfont";
	src: local("secondaryfont"),
	url("./font/p5hatty-1.ttf") format("truetype");
}

/* FontSize */
@media screen and (min-width: 1201px) {
	body{
		font-size: 16px;
	}
}
@media screen and (min-width: 701px) and (max-width: 1200px) {
	body{
		font-size: 14px;
	}
}
@media screen and (min-width: 451px) and (max-width: 700px) {
	body{
		font-size: 13px;
	}
}
@media screen and (max-width: 450px) {
	body{
		font-size: 12px;
	}
}

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	text-decoration: none;
	outline: none;
	scrollbar-width: none;
}

*:focus, *:active, *:hover {
	outline: none;
}

body {
	overflow-x: hidden;
}


body::-webkit-scrollbar {
	display: none;
}

.primary-text {
  font-family: primaryfont;
  color: #0B57FF;
  text-shadow: 3px 4px 0px #000000;
  -webkit-text-stroke: 0.2px black;
}